import store from "./store";
import {
  setAuthentication,
  logout,
  setUser,
  User,
  Authenticated,
} from "./reducer/authentication-slice";

export default store;

export { setAuthentication, logout, setUser, User, Authenticated };
